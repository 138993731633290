define('due-backoffice/components/tagging-tool/verbatim-part', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      debounce = Ember.run.debounce,
      Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    currentUser: service(),
    undo: false,
    displayField: 'title',
    keysPressed: {},

    screenheight: window.screen.availHeight / 3,

    mouseDown: function mouseDown(event) {
      event.stopPropagation();
      this.get('selectController').actions.open();
      if (this.element.querySelector('.ember-power-select-search-input')) {
        this.element.querySelector('.ember-power-select-search-input').onblur = function (event) {
          var blurEl = this;
          setTimeout(function () {
            blurEl.focus();
          }, 10);
        };
      }
    },
    keyDown: function keyDown(event) {
      var keysPressed = this.get('keysPressed');
      if (event.key != "Enter") {
        keysPressed = {};
      }
      if (event.key == "Escape") {
        event.preventDefault();
        event.stopPropagation();
      }
      keysPressed[event.key] = true;
      this.set('keysPressed', keysPressed);
      if ((keysPressed.Meta || keysPressed.Control) && event.key == "Enter") {
        event.stopPropagation();
        event.preventDefault();
        this.saveVerbatim();
      }
    },
    keyUp: function keyUp() {
      this.set('keysPressed', {});
    },


    triggerDropDownReset: false,

    usedTags: computed('selectedVerbatim.tagging_tags', 'selectedVerbatim.tagging_tags.length', 'companyTags', 'companyTags.length', function () {
      return this.get('selectedVerbatim.tagging_tags').toArray().map(function (t) {
        return t.name;
      });
    }),

    undoDisabled: computed('undo', 'savedVerbatim', function () {
      return this.get('undo') === false && !this.get('savedVerbatim') || this.get('undo') === true;
    }),

    listedFilters: computed('companyTags', 'companyTags.length', 'usedTags', 'usedTags.length', 'allSegments', function () {
      var _this = this;

      var res = [],
          tags = this.get('companyTags'),
          selectedTags = this.get('usedTags');
      if (tags !== undefined) {
        tags.forEach(function (tag, _, array) {
          _this._computeTags(array, tag, res, selectedTags);
        });
      }
      this.reloadDropDown();
      return res;
    }),

    _computeTags: function _computeTags(tags, tag, res, selectedTags) {
      var _this2 = this;

      var splitName = tag.get('name').split(' > '),
          splitTitle = tag.get('name').split('>'),
          feelingEnabled = tag.get('feeling_enabled'),
          depth = splitName.length - 1;
      tag.set('depth', splitName.length - 1);
      tag.set('displayName', splitName[tag.get('depth')]);
      tag.set('title', splitTitle[depth]);
      var mustBeKept = false;
      var subTagRegExp = "^" + tag.get('name').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";
      var subTags = tags.filter(function (item) {
        return item.get('name').match(subTagRegExp);
      });
      subTags = subTags.map(function (subTag) {
        mustBeKept = _this2._computeTags(tags, subTag, res, selectedTags);
        subTag.set('parentTag', tag);
        return subTag;
      });
      subTags = subTags.filter(function (item) {
        return item.get('due_exclusive') === true;
      });
      var disabled = selectedTags.filter(function (selected) {
        return selected == tag.get('name');
      }).length !== 0 || tag.get('not_assignable');

      if (subTags.length > 0) {
        mustBeKept = true;
        if (!tag.get('not_assignable') && tag.get('due_exclusive')) {
          var feelingOptions = [],
              groupName = { title: 'General', name: 'General' };
          this._addSentimentToObject(feelingOptions, tag, disabled);
          var generalTag = {};
          if (feelingEnabled) {
            generalTag = { title: 'General', name: 'General', type: 'general',
              realTag: tag, must_be_first: true, options: feelingOptions,
              groupName: groupName, disabled: disabled };
          } else {
            generalTag = { title: 'General', name: 'General', type: 'general', realTag: tag, must_be_first: true, disabled: disabled };
          }
          subTags.unshiftObject(generalTag);
        }
        tag.set('options', subTags);
        tag.set('groupName', { title: tag.get('title'), name: tag.get('displayName'), type: 'tag' });
      } else if (feelingEnabled) {
        this._addSentimentToObject(subTags, tag, disabled);
        tag.set('options', subTags);
        tag.set('groupName', { title: tag.get('title'), name: tag.get('displayName'), type: 'tag' });
      }

      if (depth === 0 && (mustBeKept === true || tag.get('due_exclusive') === true)) {
        mustBeKept = true;
        res.push(tag);
      }
      return mustBeKept;
    },
    _addSentimentToObject: function _addSentimentToObject(parentObject, tag, disabled) {
      ['positive', 'neutral', 'negative'].forEach(function (feeling, index, feelings) {
        var tagFeeling = { title: feeling, name: feeling, type: 'feeling', realTag: tag, feeling: feeling, must_be_first: index == 0, must_be_last: feelings.length - 1 <= index, disabled: disabled };
        parentObject.unshiftObject(tagFeeling);
      });
    },
    reloadDropDown: function reloadDropDown() {
      var _this3 = this;

      debounce(this, function () {
        _this3.toggleProperty('triggerDropDownReset');
      }, 10);
    },
    loadMoreVerbatims: function loadMoreVerbatims() {
      var _this4 = this;

      this.get('store').query('verbatim-tagging', {
        filter: {
          tagging: true,
          survey_id: this.get('survey.id'),
          zone: this.get('zoneFilter'),
          completed_at: this.get('survey.tagging_start_date'),
          startDate: this.get('startDate'),
          endDate: this.get('endDate')
        },
        page: {
          number: 1,
          size: 200
        }
      }).then(function (verbatimTagging) {
        return _this4.set('verbatimTagging', verbatimTagging);
      });
    },


    selectedVerbatim: computed('surveyId', 'verbatimTagging', 'verbatimTagging.@each', 'undo', function () {
      if (this.get('undo') === true) {
        return this.get('savedVerbatim');
      }
      return this.get('verbatimTagging').get('firstObject');
    }),

    profil: Ember.computed('selectedVerbatim', function () {
      if (this.get('selectedVerbatim.score') > 8) {
        return 'ambassador';
      }
      if (this.get('selectedVerbatim.score') < 7) {
        return 'detractor';
      }
      return 'passive';
    }),

    saveVerbatim: function saveVerbatim() {
      var _this5 = this;

      if (!this.get('selectedVerbatim')) {
        return;
      }
      var untagged_count = this.get('survey.untagged_count');
      if (this.get('selectedVerbatim.tagging_tags.length') > 0) {
        this.set('survey.untagged_count', untagged_count - 1);
      }
      this.get('selectedVerbatim').save().then(function () {
        if (_this5.get('undo') === false) {
          _this5.get('verbatimTagging').removeAt(0);
          if (_this5.get('verbatimTagging.length') == 0) {
            _this5.loadMoreVerbatims();
          }
        }
      });
      this.set('savedVerbatim', this.get('selectedVerbatim'));
      this.set('undo', false);
      this.element.querySelector('.ember-power-select-search-input').value = '';
    },
    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      addFeedbackTag: function addFeedbackTag(tagObject) {
        if (!tagObject.feeling && tagObject.groupName) throw new Error('Feeling must be defined');
        var feedbackTag = tagObject.realTag || tagObject,
            feeling = tagObject.feeling,
            feedbackData = this.get('selectedVerbatim.tagging_tags');
        this.set('triggerDropDownReset', !this.get('triggerDropDownReset'));
        feedbackData.addObject(Object.assign({
          name: feedbackTag.get('name'),
          title: feedbackTag.get('title'),
          color: feedbackTag.get('color'),
          admin_user_id: this.get('currentUser.content.id'),
          tag_id: feedbackTag.get('id')
        }, feeling && { feeling: feeling }));
        this.set('selectedVerbatim.tagging_tags', feedbackData);
        this.element.querySelector('.ember-power-select-search-input').value = '';
      },
      setSelectApi: function setSelectApi(remoteController) {
        this.set('selectController', remoteController);
      },
      removeTag: function removeTag(tag) {
        this.get('selectedVerbatim.tagging_tags').removeObject(tag);
      },
      saveVerbatim: function saveVerbatim() {
        this.saveVerbatim();
      },
      undoVerbatim: function undoVerbatim() {
        this.set('undo', true);
        this.set('survey.untagged_count', this.get('survey.untagged_count') + 1);
      },
      copyComment: function copyComment() {
        navigator.clipboard.writeText(this.get('selectedVerbatim.comment'));
      },
      copyTranslation: function copyTranslation() {
        navigator.clipboard.writeText(this.get('selectedVerbatim.verbatim_translation'));
      },
      copyId: function copyId() {
        navigator.clipboard.writeText(this.get('selectedVerbatim.id'));
      },
      searchUpdated: function searchUpdated(value) {
        this.set('displayField', value === '' ? 'title' : 'name');
      }
    }
  });
});